import React, {useContext, useState, useEffect} from 'react'

import {DataContext} from '../../dataContext'
import { useParams } from 'react-router'

import MajorityMeter from '../../utilities/MajorityMeter'
import Declaration from '../../utilities/Declaration'
import LiveUpdates from '../../utilities/LiveUpdates';
import YearButtons from '../../utilities/YearButtons';

import Party from '../../utilities/Party';
import SeatMap from '../../utilities/SeatMap';

export default function App() {
    const {data} = useContext(DataContext)
	const [seats, setSeats] = useState([])
	const [parties, setParties] = useState([])

	const {results} = data

	let totalSeats = data.config ? data.config.seats : 0
	if (!data.currentYear && data.config.seats_prev) {
		totalSeats = data.config.seats_prev
	}	
	let majoritySeats = data.config ? data.config.majority : 0
	if (!data.currentYear && data.config.majority_prev) {
		majoritySeats = data.config.majority_prev
	}

    useEffect(()=> {
        if (results.party) {
			let total = 0
			setSeats([])
			setParties([])
            results.party.partyResults.sort((a,b)=>{
				if (a.seats > b.seats) {
					return -1
				} else if (a.seats < b.seats) {
					return 1
				} else return 0
			}).map((party, i)=>{
				const color = getColor(party.nameShort)
				for (let j=0; j<party.elected; j++) {
					total++
                    setSeats( oldArray=> [ ...oldArray, { party: party.nameShort, leading: false,  id: j, color: color.color }])
				}
				for (let j=0; j<party.leading; j++) {
					total++
                    setSeats( oldArray=> [ ...oldArray, { party: party.nameShort, leading: true,  id: j, color: color.color }])
				}
				if (i <=results.party.partyResults.length-1 && i >4) {
					if (!party.seats) {
						return;
					}
				}
				setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, text: color.text, color: color.color, votesPercent: party.votesPercent,votesTotal: party.votes}])
				
			})
			if (total < totalSeats) {
				for (let k=0; k<totalSeats-total; k++) {
					let color = '#e0e0e0'
					if (localStorage.getItem('darkMode') === 'true') {
						color = "#838383"
					}
					setSeats( oldArray=> [ ...oldArray, { party: 'none', id: k, color: color }])
				}
			}
		}
	}, [results])

	const getColor = (partyName) => {
		let color = {
			color: '#D8D9D9', 
			text: 'black'
		}
		try {
			if (data.config.parties) {
				let parsedParty = data.config.parties.find(el=>el.nameShort === partyName)
				if (parsedParty) {
						color = {
							color: parsedParty.chiclet, 
							text: parsedParty.text
						}
						return color
					}
				} 
			let party = results.party.partyResults.find(el=>el.nameShort === partyName)
			if (party) {
				color.color = party.color
				color.text = 'white'

			}
			return color

		} catch (e) {
			return color

		}

	}

    return (
        <div className={'classes.seatApp h-full max-h-maxApp relative background-app '}>
			{data.error && <div className="p-2 dark:text-dark-text text-center mx-auto">Election Data Currently Unavailable</div>}
			{(results.party && seats && parties && !data.error) && 
			<div className={'classes.main flex flex-col h-full gap-1'} aria-labelledby="widgetTitle" tabIndex={0}>
				<div className={'classes.titleRow pt-2 flex gap-1'} id="widgetTitleRow">
					<div className={'classes.left  flex-grow'}>
						<div className={'classes.titleDiv flex justify-between'}>
							<h2 id="widgetTitle" className={'classes.title dark:text-dark-text text-xl sm:text-3xl font-bold'}>{data.config.title}</h2>
							{data.config.currentyear && <YearButtons />}
						</div>
						<Declaration declaration={results.declaration} majority={majoritySeats} showLeading={true} />
					</div>
					{<div className={'classes.majorityMeter min-w-[20%] pt-5 pr-3'}>
						<MajorityMeter limit={true} longText={false} seatTotal={totalSeats} majority={majoritySeats} configParties={data.config.parties} data={results.party}/>
					</div>}
				</div>
				<div className={'classes.seatContainer flex flex-col flex-grow relative overflow-hidden'}>
					<SeatMap seats={seats} />
				</div>
				<div className={'classes.partyMap flex align-middle gap-1 my-auto'}>
					{parties.map(party=>{
						return <Party key={party.id} party={party}/>
					})}
				</div>
				{data.config.election_day &&
					<LiveUpdates className={''} time={results.party.generated} />
				}
		    </div>}
        </div>
    )
}
